import React, { useState, useRef, useContext, useEffect } from 'react';
import Countdown from 'react-countdown';
import { Ralert } from './timerConfirm';
import apiService from '../../../api/apiService';
import { CartContext } from '../../../contexts/CartContext';
import { userAuth } from '../../../contexts/userContext';
import { useNavigate } from 'react-router-dom';

const InactivityTimer = () => {
	// Key to reset the timer
	const countdownRef = useRef();

	const { setItemsCount, itemsCountstart, setItemsCountstart, setCartitemcount, key, setKey } =
		useContext(CartContext);
	const { token, setToken } = useContext(userAuth);
	const [condialog, setCondialog] = useState(false);
	const [timer, setTimer] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		//console.log("1st use effect")
		let removecarttimer;
		if (timer === 'start') {
			setCondialog('OK');
			removecarttimer = setTimeout(() => {
				// console.log('removed cart');
				removecart();
			}, 60000 * 2);
		}
		return () => clearTimeout(removecarttimer);
	}, [timer]);
	const handleComplete = () => {
		handleActivity();
		// console.log('handleActivity 2');
	};

	const resetTimer = () => {
		setKey((prevKey) => prevKey + 1); // Change the key to reset the timer
	};
	const handleActivity = () => {
		resetTimer();
		// console.log('resetTimer 3');
	};

	// Renderer callback with condition
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			// return <Completionist />;
		} else {
			if (hours === 0 && minutes === 0 && seconds === 1) {
				startmodelcartitem();
				// console.log('renderer');
			}
			// console.log(hours, minutes, seconds);
			// Render a countdown
		}
		return null;
	};

	const removecart = async () => {
		console.log('removecart func');
		const formData = {};

		formData['action'] = 'delete_cart_items';
		// formData['id'] = upbook.id;

		const flechdata = await apiService(formData, token, setToken, navigate);
		if (flechdata.type === 'Success' || flechdata.type === 'success') {
			// setLoadtable(!loadtable);
			setCondialog(false);
			// setCartitemcount(0);
			// setItemsCountstart(null);
			// setKey((prevKey) => prevKey + 1);
			// setTimer('end');
			// localStorage.removeItem('sdcart_CartContext');
			// navigate('/cust-pages/dashboardc');
			setCartitemcount(0);
			setItemsCountstart(null);
			setKey((prevKey) => prevKey + 1);
			setItemsCount(null);
			setTimer('end');
			navigate('/cust-pages/dashboardc');
			localStorage.removeItem('sdcart_CartContext');
		} else {
			console.log('cart remove error');
		}
	};

	const closemodel3 = () => {
		// console.log("alert closed")
		setCondialog(false);
		removecart();
	};
	const startmodelcartitem = () => {
		setTimer('start');
	};
	const closemodel2 = () => {
		setCondialog(null);
		// console.log("removecarttimer",removecarttimer)

		setTimer('end');
		setItemsCountstart(Date.now() + 60000 * 10);

		setKey((prevKey) => prevKey + 1);
	};
	// console.log('vvvvv', condialog, key);
	return (
		<>
			<Ralert
				msgloadalert={''}
				msgtypeloadalert={'Session Timeout Warning'}
				loadalert={condialog === 'OK' ? true : false}
				closemodel={closemodel3}
				confirmbt={closemodel2}
			/>
			{itemsCountstart && (
				<Countdown
					key={key}
					date={itemsCountstart} // 60000 milliseconds = 1 minute
					//renderer={()=>null} // No need to render anything
					renderer={renderer} // No need to render anything
					// onComplete={startmodelcartitem}
					ref={countdownRef}
				/>
			)}
		</>
	);
};

export default InactivityTimer;
