import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Rodal from 'rodal';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { userAuth } from '../../../contexts/userContext';
import './Login.css';
import Icon from '../../../components/icon/Icon';
// include styles
import 'rodal/lib/rodal.css';
import { Ralert } from '../../common/vhelper/Normarodallalert';
import Loader from '../../common/vhelper/Loader';
import CountryFlagWithPhoneCodeSelector from '../demo-pages/helper/flag';
import '../demo-pages/helper/flag.css';
import apiServicewt from '../../../api/apiServicewt';
import { useTranslation } from 'react-i18next';
import LANG, { getLangWithKey } from '../../../lang';

const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h3 fw-bold mt-2'>Create Account</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-3'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { i18n } = useTranslation();

	const { darkModeStatus, themeStatus } = useDarkMode();
	const [usernameInput, setUsernameInput] = useState('');
	const [isNewUser, setIsNewUser] = useState(false);

	//reg pw show
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	//reg cpw show
	const [cshowPassword, setCshowPassword] = useState(false);

	const ctogglePasswordVisibility = () => {
		setCshowPassword(!cshowPassword);
	};

	//log pw show
	const [lshowPassword, setLshowPassword] = useState(false);

	const [qparam] = useSearchParams();

	useEffect(() => {
		if (qparam.get('code')) {
			const wcode = qparam.get('code');
			const cresult = JSON.parse(atob(wcode));
			startmodel();
			setMsgLoadalert(cresult.message);
			setMsgtypeLoadalert(cresult.type);
		}
	}, [qparam.get('code')]);

	useEffect(() => {
		if (qparam.get('logtype') === 'login') {
			setIsNewUser(false);
		}
		if (qparam.get('logtype') === 'reg') {
			setIsNewUser(true);
		}
	}, [qparam.get('logtype')]);

	const ltogglePasswordVisibility = () => {
		setLshowPassword(!lshowPassword);
	};

	const fnameRef = useRef();
	const mnameRef = useRef();
	const lnameRef = useRef();
	const emailRef = useRef();
	const phonecRef = useRef();
	const phoneRef = useRef();
	const passwordRef = useRef();
	const conpasswordRef = useRef();

	const [isemptyfn, setIsemptyfn] = useState({ error: false, msg: '*Required' });
	const [isemptymn, setIsemptymn] = useState({ error: false, msg: '*Required' });
	const [isemptyln, setIsemptyln] = useState({ error: false, msg: '*Required' });
	const [isemptye, setIsemptye] = useState({ error: false, msg: '*Required' });
	const [isemptypc, setIsemptypc] = useState({ error: false, msg: '*Required' });
	const [isemptypn, setIsemptypn] = useState({ error: false, msg: '*Required' });
	const [isemptypw, setIsemptypw] = useState({ error: false, msg: '*Required' });
	const [isemptycp, setIsemptycp] = useState({ error: false, msg: '*Required' });

	const [isempty2, setIsempty2] = useState(false);
	const [loadalert, setLoadalert] = useState(false);
	const [msgloadalert, setMsgLoadalert] = useState('');
	const [msgtypeloadalert, setMsgtypeLoadalert] = useState('');

	const [loaderv, setLoaderv] = useState(true);

	const [ppcode, setppcode] = useState({});

	const [tableOption, setTableOption] = useState([]);

	const handleCountrySelect = (selectedCountry) => {
		setIsemptypc({ error: false, msg: '*Required' });
		setppcode((pre) => {
			const temp = { ...pre };
			temp['phonecRef'] = `${selectedCountry.phoneCode}`.substring(1);
			return temp;
		});
	};

	useEffect(() => {
		const currentLang = getLangWithKey(i18n.language);

		if (!currentLang) {
			i18n.changeLanguage(LANG.EN.lng);
		}

		const fetchata = async () => {
			const item = { action: 'get_settings' };
			const flechdata = await apiServicewt(item, navigate);

			if (Array.isArray(Object.entries(flechdata.country_list))) {
				const temp = Object.entries(flechdata.country_list).map((ele) => ({
					code: ele[0],
					name: ele[1],
					phoneCode: `+${flechdata.country_phone_code[ele[0]]}`,
				}));

				setTableOption(temp);
			}
		};
		fetchata();
	}, []);

	async function signUpp() {
		let cansendapi = true;
		const charRegex = /^[a-zA-Z]+$/;
		const numberRegex = /^[0-9]+$/;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const minLengthRegex =
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*])[A-Za-z\d.!@#$%^&*]{8,}$/;

		if (!fnameRef.current.value) {
			setIsemptyfn({ error: true, msg: '*Required' });
			cansendapi = false;
		} else if (charRegex.test(`${fnameRef.current.value}`.trim())) {
			setIsemptyfn({ error: false });
		} else {
			setIsemptyfn({ error: true, msg: 'Characters Only  accepted.' });
			cansendapi = false;
		}

		if (mnameRef.current.value.length) {
			if (charRegex.test(`${mnameRef.current.value}`.trim())) {
				setIsemptymn({ error: false });
			} else {
				setIsemptymn({ error: true, msg: 'Characters Only  accepted.' });
				cansendapi = false;
			}
		}
		if (!lnameRef.current.value) {
			setIsemptyln({ error: true, msg: '*Required' });
			cansendapi = false;
		} else if (charRegex.test(`${lnameRef.current.value}`.trim())) {
			setIsemptyln({ error: false });
		} else {
			setIsemptyln({ error: true, msg: 'Characters Only  accepted.' });
			cansendapi = false;
		}
		if (!emailRef.current.value) {
			setIsemptye({ error: true, msg: '*Required' });
			cansendapi = false;
		} else if (emailRegex.test(`${emailRef.current.value}`.trim())) {
			setIsemptye({ error: false });
		} else {
			setIsemptye({ error: true, msg: 'Please include an @ in the email address.' });
			cansendapi = false;
		}
		if (!ppcode.phonecRef) {
			setIsemptypc({ error: true, msg: '*Required' });
			cansendapi = false;
		} else {
			setIsemptypc({ error: false });
		}
		if (!phoneRef.current.value) {
			setIsemptypn({ error: true, msg: '*Required' });
			cansendapi = false;
		} else if (numberRegex.test(`${phoneRef.current.value}`.trim())) {
			setIsemptypn({ error: false });
		} else {
			setIsemptypn({ error: true, msg: 'Only integers accepted' });
			cansendapi = false;
		}
		if (!passwordRef.current.value) {
			setIsemptypw({ error: true, msg: '*Required' });
			cansendapi = false;
		} else if (minLengthRegex.test(`${passwordRef.current.value}`.trim())) {
			setIsemptypw({ error: false });
		} else {
			setIsemptypw({
				error: true,
				msg: 'Must contain at least 8 characters,including UPPER/lowercase,symbles and numbers',
			});
			cansendapi = false;
		}
		if (!conpasswordRef.current.value) {
			setIsemptycp({ error: true, msg: '*Required' });
			cansendapi = false;
		} else if (minLengthRegex.test(`${conpasswordRef.current.value}`.trim())) {
			setIsemptycp({ error: false });
		} else {
			setIsemptycp({
				error: true,
				msg: 'Must contain at least 8 characters,including UPPER/lowercase,symbles and numbers',
			});
			cansendapi = false;
		}

		if (cansendapi) {
			const item = {
				action: 'registration',
				first_name: fnameRef.current.value,
				middle_name: mnameRef.current.value,
				last_name: lnameRef.current.value,
				email: emailRef.current.value,
				phone_code: ppcode.phonecRef,
				// phone_code: selectedCountry.phoneCode,
				phone: phoneRef.current.value,
				password: passwordRef.current.value,
				confirmed: conpasswordRef.current.value,
			};
			const weblink = process.env.REACT_APP_WEB_LINK;
			try {
				const res = await fetch(weblink, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
					body: JSON.stringify(item),
				});
				const result = await res.json();

				// if (res.ok) {
				if (result.type === 'Success' || result.type === 'success') {
					startmodel();
					setMsgLoadalert(result.message);
					setMsgtypeLoadalert(result.type);
				} else {
					startmodel();
					setMsgLoadalert(result.message);
					setMsgtypeLoadalert(result.type);
				}
			} catch (error) {
				startmodel2();
			}
		}
	}

	const navigate = useNavigate();

	const lemailRef = useRef();
	const lpasswordRef = useRef();

	const { token, setToken } = useContext(userAuth);

	const [isemptym, setIsemptym] = useState({ error: false, msg: '*Required' });
	const [isemptyp, setIsemptyp] = useState({ error: false, msg: '*Required' });

	// async function login()
	const login = async () => {
		let cansendapi = true;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!lemailRef.current.value) {
			setIsemptym({ error: true, msg: '*Required' });
			cansendapi = false;
		} else if (emailRegex.test(`${lemailRef.current.value}`.trim())) {
			setIsemptym({ error: false });
		} else {
			setIsemptym({ error: true, msg: 'Please include an @ in the email address.' });
			cansendapi = false;
		}
		if (!lpasswordRef.current.value) {
			setIsemptyp({ error: true, msg: '*Required' });
			cansendapi = false;
		} else {
			setIsemptyp({ error: false });
		}

		if (cansendapi) {
			const item = {
				action: 'login',
				email: lemailRef.current.value,
				password: lpasswordRef.current.value,
			};
			const weblink = process.env.REACT_APP_WEB_LINK;
			try {
				const res = await fetch(weblink, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
					body: JSON.stringify(item),
				});
				const result = await res.json();

				// if (res.ok) {
				if (result.type === 'Success' || result.type === 'success') {
					if (result.data.userrole === 'cus') {
						setToken(result.data);
						localStorage.setItem('user-auth', JSON.stringify(result.data));
						navigate('/cust-pages/dashboardc');
					} else {
						startmodel();
						setMsgLoadalert(result.message);
						setMsgtypeLoadalert(result.type);
					}
				} else {
					startmodel();
					setMsgLoadalert(result.message);
					setMsgtypeLoadalert(result.type);
				}
			} catch (error) {
				//startmodel2();
				console.log(error);
			}
		}
	};

	const closemodel = () => {
		setLoadalert(false);
	};
	const startmodel = () => {
		setLoadalert(true);
	};
	const closemodel2 = () => {
		setIsempty2(false);
	};
	const startmodel2 = () => {
		setIsempty2(true);
	};
	useEffect(() => {
		setLoaderv(true);
		const authtimer = setTimeout(() => {
			if (token.email) {
				navigate('/cust-pages/dashboardc');
			} else {
				setLoaderv(false);
			}
		}, 300);
		return () => {
			clearTimeout(authtimer);
		};
	}, [token.email]);
	if (loaderv) {
		return <Loader />;
	}

	return (
		<>
			<Ralert
				msgloadalert={msgloadalert}
				msgtypeloadalert={msgtypeloadalert}
				loadalert={loadalert}
				closemodel={closemodel}
			/>
			<Rodal visible={isempty2} onClose={closemodel2}>
				<div
					style={{
						height: '244px',
						width: '404px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						backgroundColor: themeStatus === 'dark' ? '#141414' : '#f5f5f7',
						border: themeStatus === 'dark' ? '4px solid #f5f5f7' : '4px solid #141414',
						// margin: -17,
						borderRadius: '8px',
					}}>
					<div
						style={{
							//backgroundColor:"red",
							display: 'flex',
							paddingTop: '30px',
							width: '100%',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}>
						<Icon icon='ErrorOutline' color='danger' size='6x' />{' '}
						{/* Replace with your desired icon */}
						<span
							style={{
								color: themeStatus === 'dark' ? '#e5e5e5' : '#141414',
								fontSize: '15px',
								display: 'flex',
								width: '100%',
								alignItems: 'center',
								flexDirection: 'column',
								justifyContent: 'space-between',
								fontWeight: 'bold',
							}}>
							Login Failed !
						</span>
					</div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							//gap: '25px',
							paddingBottom: '20px',
						}}>
						Please Recheck the Username and Password.
					</div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							//gap: '30px',
							paddingBottom: '20px',
						}}>
						<Button
							style={{
								width: '100px',
								height: '40px',
								borderRadius: '7px',
								fontSize: '16px',
								backgroundColor: '#e6e6e6',
								color: '#000000',
								border: '3px solid #cecfd3',
								paddingBottom: '10px',
								//color: 'primary',
								//outline: 'none',
								//border: '2px solid skyblue',
							}}
							color='danger'
							isLight
							onClick={closemodel2}>
							Close
						</Button>
					</div>
				</div>
			</Rodal>
			<div className='bg-img'>
				<PageWrapper title={isNewUser ? 'Sign Up' : 'Login'}>
					<Page
						className='p-0 mt-3'
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						{/* <div className='row h-100 align-items-center justify-content-center'> */}
						{/* <div className='col-xl-6 col-lg-6 col-md-8 shadow-3d-container'> */}
						<br />
						<br />
						<br />
						<Card
							className='shadow-3d-dark mt-1'
							data-tour='login-page'
							style={{
								maxWidth: '750px',
								maxHeight: '760px',
								//marginLeft: '200px',
							}}>
							<CardBody
								style={{
									justifyContent: 'center',
									// marginBottom: '20px'
								}}>
								<div className='text-center my-3'>
									<a
										href='http://schedulerdesk.com/'
										className={classNames(
											'text-decoration-none fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</a>
									{/* </Link> */}
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mb-1'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!!isNewUser}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setUsernameInput(false);
													setIsNewUser(false);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!isNewUser}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setUsernameInput(false);
													setIsNewUser(true);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									{isNewUser ? (
										<div className='mt-5'>
											<div className='row g-8'>
												<div className='col-4'>
													<FormGroup isFloating label='First Name'>
														<Input type='text' ref={fnameRef} />
													</FormGroup>
													{isemptyfn.error && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
															}}>
															{' '}
															{isemptyfn.msg
																? isemptyfn.msg
																: '*Required'}
														</p>
													)}
												</div>
												<div className='col-4'>
													<FormGroup isFloating label='Middle Name'>
														<Input type='text' ref={mnameRef} />
													</FormGroup>
													{isemptymn.error && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
															}}>
															{' '}
															{isemptymn.msg
																? isemptymn.msg
																: 'Characters Only  accepted.'}
														</p>
													)}
												</div>
												<div className='col-4'>
													<FormGroup isFloating label='Last Name'>
														<Input type='text' ref={lnameRef} />
													</FormGroup>
													{isemptyln.error && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
															}}>
															{' '}
															{isemptyln.msg
																? isemptyln.msg
																: '*Required'}
														</p>
													)}
												</div>
											</div>

											<div className='row g-8'>
												<div className='col-12  mt-4'>
													<FormGroup isFloating label='Your email'>
														<Input type='email' ref={emailRef} />
													</FormGroup>
													{isemptye.error && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
															}}>
															{' '}
															{isemptye.msg
																? isemptye.msg
																: '*Required'}
														</p>
													)}
												</div>
											</div>

											<div className='row g-8'>
												<div className='col-4  mt-4'>
													<FormGroup isFloating>
														{/* <Select
															placeholder='Choose...'
															list={tableOption}
															ref={phonecRef}
														/> */}
														<CountryFlagWithPhoneCodeSelector
															onSelect={handleCountrySelect}
															tableOption={tableOption}
															// isError={newadminerror.Phonec.error}
														/>
													</FormGroup>
													{isemptypc.error && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
															}}>
															{' '}
															{isemptypc.msg
																? isemptypc.msg
																: '*Required'}
														</p>
													)}
												</div>
												<div className='col-8 mt-4'>
													<FormGroup isFloating label='Mobile No:'>
														<Input type='text' ref={phoneRef} />
													</FormGroup>
													{isemptypn.error && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
															}}>
															{' '}
															{isemptypn.msg
																? isemptypn.msg
																: '*Required'}
														</p>
													)}
												</div>
											</div>

											<div className='row g-8'>
												<div className='col-6  mt-4'>
													<FormGroup isFloating label='Password'>
														<Input
															type={
																showPassword ? 'text' : 'password'
															}
															ref={passwordRef}
														/>
														<button
															type='button'
															onClick={togglePasswordVisibility}
															style={{
																position: 'absolute',
																right: '10px', // Adjust the right position as needed
																top: '50%',
																transform: 'translateY(-50%)',
																border: 'none',
																background: 'none',
																cursor: 'pointer',
															}}>
															{showPassword ? (
																<Icon
																	style={{
																		color:
																			themeStatus === 'dark'
																				? 'white'
																				: 'black',
																	}}
																	icon='Visibility'
																	size='2x'
																	// color='white'
																/>
															) : (
																<Icon
																	style={{
																		color:
																			themeStatus === 'dark'
																				? 'white'
																				: 'black',
																	}}
																	icon='VisibilityOff'
																	size='2x'
																	// color='white'
																/>
															)}{' '}
														</button>
													</FormGroup>
													{isemptypw.error && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
															}}>
															{' '}
															{isemptypw.msg
																? isemptypw.msg
																: '*Required'}
														</p>
													)}
												</div>
												<div className='col-6  mt-4'>
													<FormGroup isFloating label='Confirm Password'>
														<Input
															ref={conpasswordRef}
															type={
																cshowPassword ? 'text' : 'password'
															}
														/>
														<button
															type='button'
															onClick={ctogglePasswordVisibility}
															style={{
																position: 'absolute',
																right: '10px', // Adjust the right position as needed
																top: '50%',
																transform: 'translateY(-50%)',
																border: 'none',
																background: 'none',
																cursor: 'pointer',
															}}>
															{cshowPassword ? (
																<Icon
																	style={{
																		color:
																			themeStatus === 'dark'
																				? 'white'
																				: 'black',
																	}}
																	icon='Visibility'
																	size='2x'
																/>
															) : (
																<Icon
																	style={{
																		color:
																			themeStatus === 'dark'
																				? 'white'
																				: 'black',
																	}}
																	icon='VisibilityOff'
																	size='2x'
																/>
															)}{' '}
														</button>
													</FormGroup>
													{isemptycp.error && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
															}}>
															{' '}
															{isemptycp.msg
																? isemptycp.msg
																: '*Required'}
														</p>
													)}
												</div>
											</div>
											<div className='col-12 mt-3'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={() => signUpp()}>
													Sign Up
												</Button>
											</div>
										</div>
									) : (
										<>
											<div className='col-12 mt-4'>
												<FormGroup
													// id='lemail'
													isFloating
													label='Your Email or username'>
													<Input autoComplete='email' ref={lemailRef} />
												</FormGroup>
												{isemptym.error ? (
													<p
														style={{
															color: 'red',
															fontSize: '12px',
														}}>
														{' '}
														{isemptym.msg ? isemptym.msg : '*Required'}
													</p>
												) : (
													<br />
												)}

												<FormGroup isFloating label='Password'>
													<Input
														type={lshowPassword ? 'text' : 'password'}
														ref={lpasswordRef}
													/>
													<button
														type='button'
														onClick={ltogglePasswordVisibility}
														style={{
															position: 'absolute',
															right: '10px', // Adjust the right position as needed
															top: '50%',
															transform: 'translateY(-50%)',
															border: 'none',
															background: 'none',
															cursor: 'pointer',
														}}>
														{lshowPassword ? (
															<Icon
																style={{
																	color:
																		themeStatus === 'dark'
																			? 'white'
																			: 'black',
																}}
																icon='Visibility'
																size='2x'
																// color='white'
															/>
														) : (
															<Icon
																style={{
																	color:
																		themeStatus === 'dark'
																			? 'white'
																			: 'black',
																}}
																icon='VisibilityOff'
																size='2x'
																// color='white'
															/>
														)}{' '}
													</button>
												</FormGroup>
												{isemptyp.error ? (
													<p
														style={{
															color: 'red',
															fontSize: '12px',
														}}>
														{' '}
														{isemptyp.msg ? isemptyp.msg : '*Required'}
													</p>
												) : (
													<br />
												)}

												<a
													href='landing/forgot'
													className={classNames(
														'text-decoration-none me-3',
														{
															'link-light': darkModeStatus,
															white: !darkModeStatus,
														},
													)}
													style={{
														// color: 'white',
														// color={darkModeStatus ? 'text-light' : 'dark'},

														marginLeft: '8px',
														fontSize: 13,
													}}>
													Forgotten password?
												</a>
											</div>
											<div className='col-12 mb-5'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={login}>
													Login
												</Button>
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{!usernameInput && (
										<>
											{/* <div className='col-12 mt-3 text-center text-muted'>
												OR
											</div> */}
											{/* <div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div> */}
											{/* <div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div> */}
										</>
									)}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div
							className=''
							style={{
								// marginLeft: '240px', marginTop: '8px'
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginTop: '-27px',
							}}>
							<a
								href='landing/privacy'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}
								style={{ color: 'white' }}>
								Privacy Policy
							</a>
							<a
								href='landing/terms'
								className={classNames('link-light text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}
								style={{ color: 'white' }}>
								Terms Of Use
							</a>
							<a
								href='https://schedulerdesk.com/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}
								style={{ color: 'white' }}>
								SchedulerDesk
							</a>
						</div>
						{/* </div> */}
						{/* </div> */}
					</Page>
				</PageWrapper>
			</div>
		</>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
